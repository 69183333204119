import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, Section, Strong, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Noleggio di yacht Kateryna
			</title>
			<meta name={"description"} content={"La nostra promessa è una miscela perfetta di comfort e scoperta, creata per coloro che cercano di abbandonarsi alla bellezza della distesa dell'oceano. "} />
			<meta property={"og:title"} content={"Noleggio di yacht Kateryna"} />
			<meta property={"og:description"} content={"La nostra promessa è una miscela perfetta di comfort e scoperta, creata per coloro che cercano di abbandonarsi alla bellezza della distesa dell'oceano. "} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1605281317010-fe5ffe798166?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
		</Components.Header>
		<Components.Hero>
			<Override slot="text">
				I nostri servizi
			</Override>
			<Override slot="text1">
				Noleggio di yacht Kateryna soddisfa ogni tua esigenza. Offriamo una serie di servizi progettati per rendere ogni momento sull'acqua perfetto come il mare stesso. Scopri cosa ci distingue mentre ti guidiamo attraverso la promessa Noleggio di yacht Kateryna.
			</Override>
			<Override slot="link" href="/index">
				Home
			</Override>
			<Override slot="image" src="https://images.unsplash.com/photo-1523496922380-91d5afba98a3?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000" border-radius="30px" box-shadow="--xl" />
		</Components.Hero>
		<Section padding="80px 0 90px 0" quarkly-title="Product-1">
			<Override slot="SectionContent" align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 20px/1.5 --fontFamily-sans"
				color="#38aebd"
				text-transform="uppercase"
			>
				NAVIGA I TUOI SOGNI NELLA REALTÀ
			</Text>
			<Text
				margin="0px 0px 60px 0px"
				font="normal 600 56px/1.2 --fontFamily-serifGaramond"
				width="50%"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				md-margin="0px 0px 40px 0px"
			>
				Parti per un'avventura oceanica
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-1%20%281%29.jpg?v=2024-04-08T12:34:49.904Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-1%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.904Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-1%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.904Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-1%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.904Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-1%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.904Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-1%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.904Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-1%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.904Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-1%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.904Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-3.jpg?v=2024-04-08T12:34:49.913Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-3.jpg?v=2024-04-08T12%3A34%3A49.913Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-3.jpg?v=2024-04-08T12%3A34%3A49.913Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-3.jpg?v=2024-04-08T12%3A34%3A49.913Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-3.jpg?v=2024-04-08T12%3A34%3A49.913Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-3.jpg?v=2024-04-08T12%3A34%3A49.913Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-3.jpg?v=2024-04-08T12%3A34%3A49.913Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-3.jpg?v=2024-04-08T12%3A34%3A49.913Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="center"
				>
					<Image
						src="https://uploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-2.jpg?v=2024-04-08T12:34:49.907Z"
						display="block"
						max-width="100%"
						margin="0px 0px 25px 0px"
						srcSet="https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-2.jpg?v=2024-04-08T12%3A34%3A49.907Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-2.jpg?v=2024-04-08T12%3A34%3A49.907Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-2.jpg?v=2024-04-08T12%3A34%3A49.907Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-2.jpg?v=2024-04-08T12%3A34%3A49.907Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-2.jpg?v=2024-04-08T12%3A34%3A49.907Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-2.jpg?v=2024-04-08T12%3A34%3A49.907Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/3-2.jpg?v=2024-04-08T12%3A34%3A49.907Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Itinerari personalizzati
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--lead">
					Ogni rotta di navigazione è personalizzata, garantendo un viaggio che rispecchia i tuoi desideri.{"\n"}
					<br />
					<br />
					Calette nascoste: accesso esclusivo a paradisi naturali incontaminati.
Crociere al tramonto: serate orchestrate per crogiolarsi nell'ora d'oro in mare aperto.
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="--secondary"
				font="--lead"
				border-color="#dca654"
				text-transform="uppercase"
			>
				ALLOGGI DI LUSSO
			</Text>
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px" width="100%">
					<Text
						margin="0 0px 0px 0px"
						font="normal 600 42px/1.2 --fontFamily-serifGeorgia"
						color="#050c32"
						sm-font="normal 600 30px/1.2 --fontFamily-serifGeorgia"
						width="100%"
					>
						Concedetevi il massimo del lusso marittimo a bordo dei nostri yacht.
Cabine private: solitudine con vista sull'oceano, dotate di tutti i comfort di casa. Pranzo gourmet: delizie culinarie preparate dagli chef di bordo utilizzando gli ingredienti locali più freschi.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Attività avventurose
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--lead">
					<Strong>
						Per coloro che cercano il polso nel loro viaggio.
						<br />
					</Strong>
					{"\n"}Sport acquatici: una selezione di opzioni esilaranti come jet ski e paddleboarding. Spedizioni subacquee: tuffati nel profondo blu con avventure subacquee guidate.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="--color-light" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-2%20%281%29.jpg?v=2024-04-08T12:34:49.911Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-2%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.911Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-2%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.911Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-2%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.911Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-2%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.911Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-2%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.911Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-2%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.911Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/2-2%20%281%29.jpg?v=2024-04-08T12%3A34%3A49.911Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Servizi di relax
						</Text>
						<Text margin="0px 0px 50px 0px" font="--lead" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							<Strong>
								Esperienze tranquille per il massimo relax.{"\n"}
								<br />
							</Strong>
							Spa a bordo: massaggi e trattamenti benessere al suono delle onde. Sessioni di yoga: allinea corpo e anima con lo yoga sullo sfondo del vasto mare.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Ospitalità di eventi
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 0px 0px" color="#F7FBFF" font="--lead">
					<Strong>
						I tuoi traguardi meritano la grandiosità dell'ambientazione oceanica.{"\n"}
					</Strong>
					<br />
					Feste Private: Feste intime e grandiose su misura per i vostri gusti. Ritiri aziendali: incontri indimenticabili che preparano il terreno per il successo e il lavoro di squadra.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="--color-light" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-3.jpg?v=2024-04-08T12:34:49.923Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="cover"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						srcSet="https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-3.jpg?v=2024-04-08T12%3A34%3A49.923Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-3.jpg?v=2024-04-08T12%3A34%3A49.923Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-3.jpg?v=2024-04-08T12%3A34%3A49.923Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-3.jpg?v=2024-04-08T12%3A34%3A49.923Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-3.jpg?v=2024-04-08T12%3A34%3A49.923Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-3.jpg?v=2024-04-08T12%3A34%3A49.923Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6613bca0f0cb1d0025f4322e/images/1-3.jpg?v=2024-04-08T12%3A34%3A49.923Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
						order="-1"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Scopri di più con Noleggio di yacht Kateryna
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Per tessere la tua storia di mare o per avere maggiori dettagli sui nostri servizi esclusivi ti invitiamo a contattarci. Le profondità dell'oceano e l'ampiezza dei nostri servizi sono a tua disposizione per esplorarli e goderteli.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/faq"
							padding="0 0 0 0"
							background="0"
							color="--secondary"
							text-transform="uppercase"
							type="link"
							margin="2rem 0px 0px 0px"
						>
							FAQ -&gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6606aeb30072400020ebce37"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});